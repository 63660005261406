.u-section-1 {
  background-image: none;
}

.u-section-1 .u-sheet-1 {
  min-height: 784px;
}

.u-section-1 .u-image-1 {
  width: 926px;
  height: 64px;
  margin: 128px auto 0 25px;
}

.u-section-1 .u-image-2 {
  width: 926px;
  height: 63px;
  margin: 137px auto 0 25px;
}

.u-section-1 .u-image-3 {
  width: 926px;
  height: 63px;
  margin: -163px auto 0 25px;
}

.u-section-1 .u-image-4 {
  width: 926px;
  height: 63px;
  margin: 137px auto 0 25px;
}

.u-section-1 .u-text-1 {
  font-size: 6rem;
  font-weight: 700;
  margin: -337px 240px 0 212px;
}

.u-section-1 .u-text-2 {
  letter-spacing: 0px;
  font-size: 1.125rem;
  text-transform: none;
  margin: 303px 620px 60px 28px;
}

@media (max-width: 1199px) {
  .u-section-1 .u-sheet-1 {
    min-height: 691px;
  }

  .u-section-1 .u-image-1 {
    margin-left: 14px;
  }

  .u-section-1 .u-image-2 {
    margin-top: 133px;
    margin-right: 0;
    margin-left: auto;
  }

  .u-section-1 .u-image-3 {
    margin-top: -161px;
    margin-right: 0;
    margin-left: auto;
  }

  .u-section-1 .u-image-4 {
    margin-top: 133px;
    margin-right: 0;
    margin-left: auto;
  }

  .u-section-1 .u-text-1 {
    font-size: 5rem;
    width: auto;
    margin-top: -315px;
    margin-right: 52px;
    margin-left: 200px;
  }

  .u-section-1 .u-text-2 {
    width: auto;
    margin: 255px 448px 34px 0;
  }
}

@media (max-width: 991px) {
  .u-section-1 .u-sheet-1 {
    min-height: 579px;
  }

  .u-section-1 .u-image-1 {
    height: 50px;
    margin-top: 113px;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-1 .u-image-2 {
    height: 49px;
    margin-top: 93px;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-1 .u-image-3 {
    height: 49px;
    margin-top: -120px;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-1 .u-image-4 {
    height: 49px;
    margin-top: 93px;
    margin-right: initial;
    margin-left: initial;
    width: auto;
  }

  .u-section-1 .u-text-1 {
    font-size: 4.0625rem;
    margin-top: -237px;
    margin-right: -92px;
    margin-left: 124px;
  }

  .u-section-1 .u-text-2 {
    margin-top: 173px;
    margin-right: 228px;
    margin-bottom: 36px;
  }
}

@media (max-width: 767px) {
  .u-section-1 .u-sheet-1 {
    min-height: 531px;
  }

  .u-section-1 .u-image-1 {
    height: 35px;
    width: 100%;
    margin-top: 86px;
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-1 .u-image-2 {
    height: 35px;
    margin-top: 77px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-1 .u-image-3 {
    height: 35px;
    margin-top: -91px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-1 .u-image-4 {
    height: 35px;
    margin-top: 77px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-1 .u-text-1 {
    font-size: 3.75rem;
    margin-top: -188px;
    margin-right: 0;
    margin-left: 86px;
  }

  .u-section-1 .u-text-2 {
    margin-top: 182px;
    margin-right: 48px;
    margin-bottom: 60px;
  }
}

@media (max-width: 575px) {
  .u-section-1 .u-sheet-1 {
    min-height: 490px;
  }

  .u-section-1 .u-image-1 {
    height: 28px;
    margin-top: 60px;
  }

  .u-section-1 .u-image-2 {
    height: 28px;
    margin-top: 67px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-1 .u-image-3 {
    height: 27px;
    margin-top: -75px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-1 .u-image-4 {
    height: 28px;
    margin-top: 68px;
    width: auto;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-1 .u-text-1 {
    font-size: 3rem;
    margin-top: -164px;
    margin-right: -50px;
    margin-left: 50px;
  }

  .u-section-1 .u-text-2 {
    margin-top: 97px;
    margin-right: 0;
  }
} .u-section-2 {
  background-image: none;
}

.u-section-2 .u-sheet-1 {
  min-height: 741px;
}

.u-section-2 .u-layout-wrap-1 {
  margin: 0;
}

.u-section-2 .u-layout-cell-1 {
  min-height: 741px;
}

.u-section-2 .u-container-layout-1 {
  padding: 14px 0;
}

.u-section-2 .u-text-1 {
  font-size: 3.75rem;
  text-transform: none;
  letter-spacing: normal;
  font-weight: 700;
  font-family: Oswald, sans-serif;
  margin: 62px -30px 0 80px;
}

.u-section-2 .u-list-1 {
  grid-template-rows: auto;
  width: 494px;
  margin: 49px -30px 0 auto;
}

.u-section-2 .u-repeater-1 {
  grid-template-columns: calc(50% - 15px) calc(50% - 15px);
  grid-gap: 30px 30px;
  min-height: 416px;
}

.u-section-2 .u-list-item-1 {
  box-shadow: 0px 0px 0px rgba(41,46,51,0);
  transition-duration: 0.5s;
  text-shadow: 0px 0px 0px rgba(0,0,0,0);
  transform: translateX(0px) translateY(0px) scale(1) rotate(0deg);
}

.u-section-2 .u-container-layout-2 {
  padding: 30px 0;
}

.u-section-2 .u-text-2 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.25rem;
  font-weight: 700;
  margin: 34px 30px 0;
}

.u-section-2 .u-text-3 {
  font-family: Archivo;
  margin: 23px -15px 0 8px;
}

.u-section-2 .u-list-item-2 {
  box-shadow: 0px 0px 0px rgba(41,46,51,0);
  transition-duration: 0.5s;
  text-shadow: 0px 0px 0px rgba(0,0,0,0);
  transform: translateX(0px) translateY(0px) scale(1) rotate(0deg);
}

.u-section-2 .u-container-layout-3 {
  padding: 30px 0;
}

.u-section-2 .u-text-4 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.25rem;
  font-weight: 700;
  margin: 34px 30px 0;
}

.u-section-2 .u-text-5 {
  font-family: Archivo;
  margin: 23px -15px 0 8px;
}

.u-section-2 .u-list-item-3 {
  box-shadow: 0px 0px 0px rgba(41,46,51,0);
  transition-duration: 0.5s;
  text-shadow: 0px 0px 0px rgba(0,0,0,0);
  transform: translateX(0px) translateY(0px) scale(1) rotate(0deg);
}

.u-section-2 .u-container-layout-4 {
  padding: 30px 0;
}

.u-section-2 .u-text-6 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.25rem;
  font-weight: 700;
  margin: 34px 30px 0;
}

.u-section-2 .u-text-7 {
  font-family: Archivo;
  margin: 23px -15px 0 8px;
}

.u-section-2 .u-list-item-4 {
  box-shadow: 0px 0px 0px rgba(41,46,51,0);
  transition-duration: 0.5s;
  text-shadow: 0px 0px 0px rgba(0,0,0,0);
  transform: translateX(0px) translateY(0px) scale(1) rotate(0deg);
}

.u-section-2 .u-container-layout-5 {
  padding: 30px 0;
}

.u-section-2 .u-text-8 {
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 1.25rem;
  font-weight: 700;
  margin: 34px 30px 0;
}

.u-section-2 .u-text-9 {
  font-family: Archivo;
  margin: 23px -15px 0 8px;
}

.u-section-2 .u-layout-cell-2 {
  min-height: 741px;
}

.u-section-2 .u-container-layout-6 {
  padding: 0;
}

.u-section-2 .u-shape-1 {
  width: 452px;
  height: 452px;
  background-image: none;
  margin: 145px 36px 0 auto;
}

.u-section-2 .u-image-1 {
  width: 641px;
  height: 521px;
  margin: -468px -31px 0 auto;
}

@media (max-width: 1199px) {
  .u-section-2 .u-sheet-1 {
    min-height: 590px;
  }

  .u-section-2 .u-layout-wrap-1 {
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-2 .u-layout-cell-1 {
    min-height: 611px;
  }

  .u-section-2 .u-container-layout-1 {
    padding-left: 37px;
  }

  .u-section-2 .u-text-1 {
    font-size: 3rem;
    margin-right: 0;
    margin-left: 0;
  }

  .u-section-2 .u-list-1 {
    width: 407px;
    margin-right: 0;
  }

  .u-section-2 .u-repeater-1 {
    grid-template-columns: repeat(2, calc(50% - 15px));
    min-height: 344px;
  }

  .u-section-2 .u-text-2 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-2 .u-text-3 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-2 .u-text-4 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-2 .u-text-5 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-2 .u-text-6 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-2 .u-text-7 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-2 .u-text-8 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-2 .u-text-9 {
    margin-left: 0;
    margin-right: 0;
  }

  .u-section-2 .u-layout-cell-2 {
    min-height: 811px;
  }

  .u-section-2 .u-shape-1 {
    margin-top: 221px;
    margin-right: 7px;
  }

  .u-section-2 .u-image-1 {
    width: 533px;
    height: 433px;
    margin-top: -433px;
    margin-right: -33px;
  }
}

@media (max-width: 991px) {
  .u-section-2 .u-sheet-1 {
    min-height: 452px;
  }

  .u-section-2 .u-layout-cell-1 {
    min-height: 100px;
  }

  .u-section-2 .u-container-layout-1 {
    padding-top: 50px;
    padding-bottom: 50px;
    padding-left: 0;
  }

  .u-section-2 .u-text-1 {
    font-size: 2.25rem;
  }

  .u-section-2 .u-list-1 {
    width: 312px;
  }

  .u-section-2 .u-repeater-1 {
    grid-template-columns: 100%;
  }

  .u-section-2 .u-layout-cell-2 {
    min-height: 1212px;
  }

  .u-section-2 .u-shape-1 {
    width: 408px;
    height: 408px;
    margin-top: 402px;
    margin-right: -34px;
  }

  .u-section-2 .u-image-1 {
    height: 331px;
    width: 408px;
    margin-top: -353px;
    margin-right: -23px;
  }
}

@media (max-width: 767px) {
  .u-section-2 .u-sheet-1 {
    min-height: 1721px;
  }

  .u-section-2 .u-layout-wrap-1 {
    margin-top: 91px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-2 .u-layout-cell-1 {
    min-height: 1100px;
  }

  .u-section-2 .u-container-layout-1 {
    padding-top: 20px;
    padding-bottom: 14px;
  }

  .u-section-2 .u-text-1 {
    font-size: 3rem;
    margin-top: 0;
  }

  .u-section-2 .u-list-1 {
    margin-top: 33px;
    margin-right: auto;
  }

  .u-section-2 .u-repeater-1 {
    min-height: 927px;
  }

  .u-section-2 .u-layout-cell-2 {
    min-height: 561px;
  }

  .u-section-2 .u-container-layout-6 {
    padding-top: 22px;
    padding-bottom: 22px;
  }

  .u-section-2 .u-shape-1 {
    margin-top: 11px;
    margin-left: 71px;
  }

  .u-section-2 .u-image-1 {
    margin-top: -363px;
    margin-right: auto;
  }
}

@media (max-width: 575px) {
  .u-section-2 .u-sheet-1 {
    min-height: 1408px;
  }

  .u-section-2 .u-layout-wrap-1 {
    margin-top: 1px;
    margin-bottom: 1px;
    margin-right: initial;
    margin-left: initial;
  }

  .u-section-2 .u-layout-cell-1 {
    min-height: 1061px;
  }

  .u-section-2 .u-container-layout-1 {
    padding-top: 0;
  }

  .u-section-2 .u-text-1 {
    font-size: 2.25rem;
  }

  .u-section-2 .u-layout-cell-2 {
    min-height: 363px;
  }

  .u-section-2 .u-container-layout-6 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .u-section-2 .u-shape-1 {
    width: 340px;
    height: 340px;
    margin-top: -7px;
    margin-right: 0;
    margin-left: -3px;
  }

  .u-section-2 .u-image-1 {
    height: 256px;
    width: 340px;
    margin-top: -291px;
    margin-left: -14px;
  }
}

.u-section-2 .u-list-item-1,
.u-section-2 .u-list-item-1:before,
.u-section-2 .u-list-item-1 > .u-container-layout:before {
  transition-property: fill, color, background-color, stroke-width, border-style, border-width, border-top-width, border-left-width, border-right-width, border-bottom-width, custom-border, borders, box-shadow, text-shadow, opacity, border-radius, stroke, border-color, font-size, font-style, font-weight, text-decoration, letter-spacing, transform, background-image, image-zoom, background-size, background-position;
}

.u-section-2 .u-list-item-1.u-list-item-1.u-list-item-1:hover {
  transform: scale(1.1) translateX(0px) translateY(0px) !important;
  box-shadow: 0px 0px 0px  rgba(0,0,0,0) !important;
  text-shadow: 0px 0px 0px rgba(0,0,0,0) !important;
}

.u-section-2 .u-list-item-1 {
  border-color: transparent !important;
}

.u-section-2 .u-list-item-1.u-list-item-1.u-list-item-1.hover {
  transform: scale(1.1) translateX(0px) translateY(0px) !important;
  box-shadow: 0px 0px 0px  rgba(0,0,0,0) !important;
  text-shadow: 0px 0px 0px rgba(0,0,0,0) !important;
}

.u-section-2 .u-list-item-1 {
  border-color: transparent !important;
}

.u-section-2 .u-container-layout:hover .u-list-item-1 {
  transform: rotate(0deg) scale(1) translateX(0px) translateY(0px) !important;
}

.u-container-layout.hover .u-section-2 .u-list-item-1 {
  transform: rotate(0deg) scale(1) translateX(0px) translateY(0px) !important;
}

.u-section-2 .u-list-item-2,
.u-section-2 .u-list-item-2:before,
.u-section-2 .u-list-item-2 > .u-container-layout:before {
  transition-property: fill, color, background-color, stroke-width, border-style, border-width, border-top-width, border-left-width, border-right-width, border-bottom-width, custom-border, borders, box-shadow, text-shadow, opacity, border-radius, stroke, border-color, font-size, font-style, font-weight, text-decoration, letter-spacing, transform, background-image, image-zoom, background-size, background-position;
}

.u-section-2 .u-list-item-2.u-list-item-2.u-list-item-2:hover {
  transform: scale(1.1) translateX(0px) translateY(0px) !important;
  box-shadow: 0px 0px 0px  rgba(0,0,0,0) !important;
  text-shadow: 0px 0px 0px rgba(0,0,0,0) !important;
}

.u-section-2 .u-list-item-2 {
  border-color: transparent !important;
}

.u-section-2 .u-list-item-2.u-list-item-2.u-list-item-2.hover {
  transform: scale(1.1) translateX(0px) translateY(0px) !important;
  box-shadow: 0px 0px 0px  rgba(0,0,0,0) !important;
  text-shadow: 0px 0px 0px rgba(0,0,0,0) !important;
}

.u-section-2 .u-list-item-2 {
  border-color: transparent !important;
}

.u-section-2 .u-container-layout:hover .u-list-item-2 {
  transform: rotate(0deg) scale(1) translateX(0px) translateY(0px) !important;
}

.u-container-layout.hover .u-section-2 .u-list-item-2 {
  transform: rotate(0deg) scale(1) translateX(0px) translateY(0px) !important;
}

.u-section-2 .u-list-item-3,
.u-section-2 .u-list-item-3:before,
.u-section-2 .u-list-item-3 > .u-container-layout:before {
  transition-property: fill, color, background-color, stroke-width, border-style, border-width, border-top-width, border-left-width, border-right-width, border-bottom-width, custom-border, borders, box-shadow, text-shadow, opacity, border-radius, stroke, border-color, font-size, font-style, font-weight, text-decoration, letter-spacing, transform, background-image, image-zoom, background-size, background-position;
}

.u-section-2 .u-list-item-3.u-list-item-3.u-list-item-3:hover {
  transform: scale(1.1) translateX(0px) translateY(0px) !important;
  box-shadow: 0px 0px 0px  rgba(0,0,0,0) !important;
  text-shadow: 0px 0px 0px rgba(0,0,0,0) !important;
}

.u-section-2 .u-list-item-3 {
  border-color: transparent !important;
}

.u-section-2 .u-list-item-3.u-list-item-3.u-list-item-3.hover {
  transform: scale(1.1) translateX(0px) translateY(0px) !important;
  box-shadow: 0px 0px 0px  rgba(0,0,0,0) !important;
  text-shadow: 0px 0px 0px rgba(0,0,0,0) !important;
}

.u-section-2 .u-list-item-3 {
  border-color: transparent !important;
}

.u-section-2 .u-container-layout:hover .u-list-item-3 {
  transform: rotate(0deg) scale(1) translateX(0px) translateY(0px) !important;
}

.u-container-layout.hover .u-section-2 .u-list-item-3 {
  transform: rotate(0deg) scale(1) translateX(0px) translateY(0px) !important;
}

.u-section-2 .u-list-item-4,
.u-section-2 .u-list-item-4:before,
.u-section-2 .u-list-item-4 > .u-container-layout:before {
  transition-property: fill, color, background-color, stroke-width, border-style, border-width, border-top-width, border-left-width, border-right-width, border-bottom-width, custom-border, borders, box-shadow, text-shadow, opacity, border-radius, stroke, border-color, font-size, font-style, font-weight, text-decoration, letter-spacing, transform, background-image, image-zoom, background-size, background-position;
}

.u-section-2 .u-list-item-4.u-list-item-4.u-list-item-4:hover {
  transform: scale(1.1) translateX(0px) translateY(0px) !important;
  box-shadow: 0px 0px 0px  rgba(0,0,0,0) !important;
  text-shadow: 0px 0px 0px rgba(0,0,0,0) !important;
}

.u-section-2 .u-list-item-4 {
  border-color: transparent !important;
}

.u-section-2 .u-list-item-4.u-list-item-4.u-list-item-4.hover {
  transform: scale(1.1) translateX(0px) translateY(0px) !important;
  box-shadow: 0px 0px 0px  rgba(0,0,0,0) !important;
  text-shadow: 0px 0px 0px rgba(0,0,0,0) !important;
}

.u-section-2 .u-list-item-4 {
  border-color: transparent !important;
}

.u-section-2 .u-container-layout:hover .u-list-item-4 {
  transform: rotate(0deg) scale(1) translateX(0px) translateY(0px) !important;
}

.u-container-layout.hover .u-section-2 .u-list-item-4 {
  transform: rotate(0deg) scale(1) translateX(0px) translateY(0px) !important;
}.u-section-3 .u-sheet-1 {
  min-height: 20px;
}
/* variables */
:root{
    --primaryGradient: linear-gradient(to right, #FF4B2B, #FF416C);
	--primarySolid: #FF4B2B;
    --secondaryGradient: linear-gradient(to right, #09f1b8, #00a2ff, #ff00d2, #fed90f);
	--backgroundGradient: linear-gradient(to right, #d1baba, #dfdfdf);
	--blackGradient: linear-gradient(to right, #110e0e, #1b1b1b);
    --backgroundColor: #eeeeee;
	--buttonHover: #6d30f0;
}

/* reset */
body,p,a,ul,li,h1,h2{
    margin: 0;
    padding: 0;
    text-decoration: none;
}
h1,h2,h3{
	text-align: center;
}
a{
	color: inherit;
}
li {
    list-style-type: none;
}

/* base styles */
* {
	box-sizing: border-box;
}
body.vsc-initialized{
	height: 100vh;
}
#root{
	height: 100%;
	width: 100%;
	overflow: auto;
}

.app{
	position: relative;
	height: 100%;
	overflow: auto;
}

.app main{
	height:100%;
	overflow: auto;
}
body{
    background-color: var(--backgroundColor);
    height: 100%;
}
button.regular{
	display: inline-block;
    border-radius: 20px;
	border: 1px solid var(--primarySolid);
	background-image: var(--primaryGradient);
	color: white;
	font-size: 12px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
	box-shadow: 1px 2px 3px rgba(0,0,0,0.6);
	cursor: pointer;
	width: auto;
	margin: auto;
}
button.regular:hover{
    background: var(--buttonHover);
	border-color: var(--buttonHover);
}
input{
	background-color: #eee;
	border: none;
	padding: 5px;
	margin: 5px;
}

/* Position and sizing of burger button */
.bm-burger-button {
	position: absolute;
	top: 25%;
	text-align: left;
	margin-top: 7px;
	width: 30px;
	height: 25px;
	left: 20px;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
	background: rgb(0, 0, 0);
  }
  
  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
	background: #ffffff;
  }
  
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
	margin-top: 20px;
	height: 30px;
	width: 30px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
	background: #bdc3c7;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
	position: fixed;
	height: 100%;
  }
  
  /* General sidebar styles */
  .bm-menu {
	position: relative;
	background: #FF416C;
	background: -webkit-linear-gradient(to right, #FF4B2B, #FF416C);
	background: linear-gradient(to right, #FF4B2B, #FF416C);
	padding: 2.5em 1.5em 0;
	overflow:hidden !important;
  }

  /* .bm-item{
	cursor: pointer;
	color: #eee;
	font-family: 'Raleway', Arial, sans-serif;
	font-weight: bold;
	font-size: 1.2em;
  } */

  button.bm-item{
	background-color: #c99f9f;
	width:100%;
	text-align: center;
	font-size: 1em;
	color: #2e2b2b;
  }

  .bm-item:hover{
	color: #000000;
  }
  
  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
	fill: #373a47;
  }
  
  /* Wrapper for item list */
  .bm-item-list {
	color: #b8b7ad;
	padding: 0.8em;
  }
  
  /* Styling of overlay */
  .bm-overlay {
	background: rgba(0, 0, 0, 0.3);
  }
/*Ending Necessary CSS for Hamburger Menu*/
  select{
	background-color: white;
	width: 300px;
	height: 45px;
	border: 0px;
	font-size: 15px;
	color: grey !important;
	text-align: center;
	font-family: 'Montserrat', sans-serif;
	font-size: 24px !important;
	border-radius: 5px;
  }


/* fonts */

/* mobile styles */

/*Headbar Styling*/
.headbar{
	display: grid;
	grid-template-columns: repeat(3,1fr);
	width: 100%;
	position: relative;
	top: 0;
	background: #FF416C;
	background: linear-gradient(to right, #FF4B2B, #FF416C);
	background-repeat: no-repeat;
	background-size: cover;
	background-position: 0 0;
	text-align: left;
	height: 75px;
	align-items: center;
	grid-template-rows: max-content;
	/* height: 100%; */
}
.headbar div{
	text-align: center;
	max-width: 100%;
}
.headbar .headlogo h1{
	color: #eee;
	font-size: 34px;
}
.headbar .headlogo .Logo{
	color: #eee;
	font-size: 34px;
	text-decoration: none;
}
.headbar div:nth-child(2){
	grid-column: 2;
	grid-row: 2;
}
.rightcorner{
	grid-column: 3;
	grid-row: 2;
	
	color: #eee;
	word-break: break-all;
	text-align: right !important;
	margin-right: 12px;
	height: 75px;
}
.rightcorner p{
	position: relative;
	margin-top: 30px !important;
	font-size: 11px !important;
}
.headbar button.logout{
	display: inline-block;
    border-radius: 20px;
	border: 1px solid white;
	background-color: white;
	color: black;
	font-size: 20px;
	font-weight: bold;
	padding: 12px 45px;
	letter-spacing: 1px;
	text-transform: uppercase;
	transition: transform 80ms ease-in;
	box-shadow: 1px 2px 3px rgba(0,0,0,0.6);
	cursor: pointer;
}
button.logout:hover{
    background: var(--buttonHover);
	border-color: var(--buttonHover);
}
/*Body Stying*/
.body{
	display: grid;
	grid-template-columns: 1fr;
	text-align: center;
}
.body h1{
	margin-top: 10px;
}

/*Band List Grid Styling */
.band-list{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
	width: 100%;
	padding-left: 10px;
	padding-right: 10px;
}
.band-list li img{
	width: 100%;
}
.band-list li[id='active']{
	background-color: var(--backgroundColor) !important;
	text-align: center;
	background-image: var(--primaryGradient);
	color: white;
	border-radius: 15px;
}
.band-list li p{
	font-size: 15px;
	margin-top: 10px;
	margin-left: 3px;
	margin-right: 3px;
	font-family: 'Tahoma';
	word-break: break-all;
}
.band-list li:hover{
	transform: scale(1.1); 
	transition: all .2s ease-in-out;

}

/*Band Page Styling */
.bandpage .band {
	position: relative;
	display: grid;
	place-content: center;
	background-color: black;
	height: 100%;
	color: white;
}
.bandpage .band h1{
	margin-top: 20px;
	color: var(--primarySolid);
	word-break: break-all;
}
.bandpage .band p{
	text-align: center;
	word-break: break-all;
}
.bandpage .band img{
	position: relative;
	margin: auto;
}
.bandpage .band button{
	margin-bottom: 20px;
}
.bandpage .overlayactive{
	display: grid;
	position: absolute;
	width: 100%;
	height: 100%;
	background-image: linear-gradient(315deg, #08e1ae 0%, #98de5b 74%);
	z-index: 999;
	opacity: 0.95;
}
.bandpage .overlayinactive{
	display: none;
}
.bandpage .overlayactive h1{
	display: block;
	margin: auto;
	color: white;
	word-break: break-all;
}
.bandpage .shadowactive{
	background-color: grey;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	opacity: .75;
	z-index: 1001;
	word-break: break-all;
}
.bandpage .shadowinactive{
	display: none;
}
.bandpage .confirmationactive{
	display: grid;
	grid-template-columns: 1fr 1fr;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1002;
	background-color: white;
	border-radius: 10px;
	width: 80%;
	height: 20%;
}
.bandpage .confirmationinactive{
	display: none;
}
.bandpage .confirmationactive p{
	grid-row: 1;
	grid-column: span 2;
	font-family: 'Lato', sans-serif;
	color: grey;
	font-size: 15px;
	margin: auto;
}
.bandpage .confirmationactive button[id="yes"]{
	grid-column: 1;
	grid-row: 2;
	border-radius: 0 0 0 10px;
	border: none;
	background-color: var(--primarySolid);
	color: white
}
.bandpage .confirmationactive button[id="no"]{
	grid-column: 2;
	grid-row: 2;
	border-radius: 0 0 10px;
	border: none;
}
/*User Home Styling */
.userhome{
	height: 100%;
}
.userhome .body{
	display: block;
	height: 100vh;
}
.userhome .colorLogo{
	padding: 10px !important;
	margin: 0 !important;
	display: inline-block;
}
.userhome .qrinactive{
	display: none;
}
.userhome .qractive{
	display: grid;
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	background-image: linear-gradient(315deg, #b3b3b3 0%, #ffffff 74%);
	z-index: 999;
	opacity: 0.95;
}
.userhome .qractive .QRCode{
	display: block;
	margin: auto;
}
.userhome button.clear{
	position: absolute;
	right: 0;
	top: 25px;
	width: 120px;
	grid-column: 3;	
	background-color: transparent;
	height: 30px;
	margin: auto;
	margin-right: 10px;
	cursor: pointer;
	border: none;
}
/* .purchaseactive {
	height: auto;
	transition: 0.1s ease-in-out;
	padding-bottom: 25px;
}
.purchaseinactive {
	height: 0%;
}
.purchaseinactive .band-list{
	display: none;
}
.created{
	max-height: auto;
	transition: all 0.1s ease-in-out;
	padding-bottom: 25px;
}
.createdinactive{
	height: 0%;
}
.createdinactive .band-list{
	display: none;
}
.purchasedBox{
	position: relative;
	border-radius: 10px;
	background-color: #eeeeee;	
	border-radius: 0 0 0 20px;
}
.purchasedBox h2{
	font-weight: bold !important;
} */
/* .createdBox{
	position: relative;
	border-radius: 10px;
	background-color: #eeeeee;
	border-radius: 0 0 0 20px;
}
.createdBox h2{
	font-weight: bold !important; */
/* } */

/*Create Page Styling */
.body{
	gap: 10px;
}
.body form{
	display: grid;
	grid-template-columns: 1fr;
	place-items: center;
	gap: 10px;
}
input[id='regular']{
	background-color: white;
}
.body select{
	margin-top: 10px;
}
.body p{
	margin-top: 10px;
}
.body [class*=Mui]{
	margin: 10px;
}
.body [class*=Mui] input{
	width: 100%;
}
[class = "rcp rcp-dark"]{
	width: 263px !important;
}
[class = "rcp-saturation"]{
	height: 150px;
}
[class = "rcp-fields"]{
	height: 0px;
	overflow: hidden;
}
[class = "rcp-hue"]{
	height:20px !important;
}
[class = "rcp-hue-cursor"]{
	height:25px !important;
	width:25px !important;
}
.body button{
	margin-top: 10px;
}
.create-event .shadowactive{
	background-color: grey;
	position: absolute;
	width: 100%;
	height: 100%;
	top: 0;
	opacity: .75;
	z-index: 1001;
	word-break: break-all;
}
.create-event .shadowinactive{
	display: none;
}
.create-event .confirmationactive{
	display: grid;
	grid-template-columns: 1fr 1fr;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1002;
	background-color: white;
	border-radius: 10px;
	width: 80%;
	height: 20%;
}
.create-event .confirmationinactive{
	display: none;
	text-align: center;
}
.create-event .confirmationactive p{
	grid-row: 1;
	grid-column: span 2;
	font-family: 'Lato', sans-serif;
	color: grey;
	font-size: 15px;
	margin: auto;
}
/* Search Page Styling */ 
.searchhome .body{
	display: grid;
	gap: 50px;
	grid-template-rows: repeat(6, auto);
	place-items: center;
}

.searchhome h1{
	margin-top: 100px !important;
}
/* Org Page Styling */
.orgpage .shadowactive{
	background-color: grey;
	position: absolute;
	width: 100%;
	height: 100vh;
	top: 0;
	opacity: .75;
	z-index: 1;
	word-break: break-all;
}
.orgpage .shadowinactive{
	display: none;
}
.orgpage .popupinactive .x{
	display: none;
}
.orgpage .popupactive{
	display: grid;
	grid-template-rows: 15px auto;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 2;
	background-color: black;
	border-radius: 10px;
	width: 80%;
	height: 50%;
	
}
.orgpage .popupactive .x{
	height:30px;
	margin-left: 95%;
	border: none;
	background-color: red;
	color: white;
	font-weight: bold;
	cursor: pointer;
	z-index: 2;
	border-radius: 0 10px 0 0;
}
/* Band Popup Styling */
.band-popup{
	position: relative;
	display: grid;
	max-height:100%;
	color: white;
	place-content: center;
	row-gap: 2%;
	overflow: hidden;
}
.band-popup p{
	margin-top:0 !important;
	margin-bottom:0 !important;
	text-align: center;
}
.band-popup h2{
	margin-top:0 !important;
	margin-bottom:0 !important;
}
.band-popup .wrapper{
	display: relative;
	max-width: auto;
	max-height: auto;
}
.band-popup img{
	display: relative;
	height: 101%;
	width: 101%;
	margin-left: -1px;
	margin-top: -1px;
}


/* Org Band List Styling */ 
.org-list{
	display: grid;
	grid-template-rows: repeat(6, auto);
	place-items: center;
}
.org-list li:hover{
	transform: scale(1.05); 
	transition: all .25s ease-in-out;

}
.org-list li p{
	font-weight: bold;
	font-size: 30px;
	cursor: pointer;
}

/*Org Band List Grid Styling */
.org-band-list{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 10px;
	width: 100%;
	padding-left: 30px;
	padding-right: 30px;
}
.org-band-list li[id='active']{
	background-color: var(--backgroundColor) !important;
	text-align: center;
	background-image: var(--primaryGradient);
	color: white;
	border-radius: 15px;
	cursor: pointer;
}
.org-band-list li h1{
	font-size: 32px;
	font-family: 'Tahoma';
}
.org-band-list li h2{
	font-size: 22px;
	font-family: 'Tahoma';
}
.org-band-list li p{
	font-size: 20px;
	margin-top: 10px;
	margin-left: 3px;
	margin-right: 3px;
	font-family: 'Tahoma';
	word-break: break-all;
}
.org-band-list li:hover{
	transform: scale(1.05); 
	transition: all .25s ease-in-out;

}

/*Verify Page Styling*/
.verifyhome .body h1{
	margin-top: 10px;
}
.verifyhome .body{
	gap: 100px;
	place-items: center;
}
.verifyhome .body :nth-child(3){margin-top: -75px;}
.verifyhome .body :nth-child(4){margin-top: -120px;}
.verifyhome .body p{
	font-size: 25px ;
	font-family: 'Fira Sans', sans-serif;
}

/*Invite Page Styling*/
.invitehome{
	height:100%;
}
.invitehome .body{
	place-items: center;
	height: 100%;
	grid-template-rows: .25fr .05fr .05fr .1fr .1fr .1fr .1fr !important;
}
.invitehome .body h1{
	margin-top: 10px;
}
.invitehome .body p{
	font-size: 25px ;
	font-family: 'Fira Sans', sans-serif;
}
.invitehome .body input{
	width: 250px;
}
.invitehome .body button{
	margin-bottom: 50px;
}
.invitehome .scaninactive{
	display: none;
}
.invitehome .scanactive{
	display: grid;
	height: 100vh;
	width: 100%;
	position: absolute;
	background-color: black;
	top: 0;

}
.invitehome .scanactive .scanner{
	display: block;
	margin: auto;
	width: 75%;
	height: 75%;
}
.invitehome .scanactive video{
	margin: auto;
}
.invitehome .shadowactive{
	display: grid;
	position: absolute;
	background-color: grey;
	width: 100%;
	height: 100%;
	opacity: .75;
	z-index: 1001;
	top: 0;
}
.invitehome .shadowinactive{
	display: none;
}
.invitehome .confirmationactive{
	display: grid;
	grid-template-columns: 1fr 1fr;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 1002;
	background-color: white;
	border-radius: 10px;
	width: 80%;
	height: 20%;
}
.invitehome .confirmationinactive{
	display: none;
}
.invitehome .confirmationactive p{
	grid-row: 1;
	grid-column: span 2;
	font-family: 'Lato', sans-serif;
	color: grey;
	font-size: 15px;
	margin: auto;
}
/* Attendee Page Styling */
.attendeehome{
	height:100%;
}
.attendeehome .body{
	place-items: center;
	height: 100%;
	grid-template-rows: .25fr .05fr .05fr .1fr .1fr .1fr .1fr !important;
}
.attendeehome .body h1{
	margin-top: 10px;
}
.attendeehome .body p{
	font-size: 20px ;
	font-family: 'Fira Sans', sans-serif;
}
.attendeehome .body li p{
	margin: 0;
}
/*Edit Page Stylig*/
.edithome .body{
	gap: 50px;
	grid-template-rows: repeat(6, auto);
	place-items: center;
}
.edithome .body h1{
	margin-top: 10px;
	grid-row: 1;
}
.edithome .body h2{
	grid-row: 2;
}
.edithome .body select[id="bandSelect"]{
	grid-row: 3 !important;
}
.edithome .body select[id="editFieldSelect"]{
	grid-row: 4;
} 
.edithome .body form{
	grid-row: 5;
}
.editHome button{
	grid-row: 6;
}
/* .edithome .body select[id="bandSelect"]{
	position: absolute;
	top: 30%;
}
.edithome .body select[id="editFieldSelect"]{
	position: absolute;
	top: 35%;
} */
/* .edithome .body form{
	position: relative;
	top: 150%;
} */
.edithome .body p{
	font-size: 25px ;
	font-family: 'Fira Sans', sans-serif;
}
.invitehome .body input{
	width: 250px;
}

/*Login/Signup Page Styling */

.socialbuttons{
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-template-rows: 55px;
}

.socialbuttons button[id="google"]{
	grid-column: 2;
	background-color: var(--backgroundColor);
	border: none;
	position: relative;
	padding: 0;
}
.socialbuttons button[id="google"] img{
	position: absolute;
	right: 15px;
	top: 0;
	cursor: pointer;
}
.socialbuttons button[id="facebook"]{
	grid-column: 3;
	background-color: var(--backgroundColor);
	border: none;
	position: relative;
	padding: 0;
}
.socialbuttons button[id="facebook"] img{
	position: absolute;
	left: 15px;
	top: 0;
	cursor: pointer;
}
.login-wrapper .body h1{
	margin-top: 100px;
}

.signup .body h1{
	margin-top: 100px;
}


/* small tablet styles */ 
@media screen and (min-width: 620px){

}

/* large tablet and laptop styles */ 
@media screen and (min-width: 960px){
	/*Band List Grid Styling */
	.band-list{
		display: grid;
		grid-template-columns: repeat(6, 1fr);
	}
	.band-list li img{
		width: 100%;
	}
	.band-list li{
		background-color: var(--backgroundColor) !important;
		margin-left: 5px;
		margin-right: 5px;
	}
	.rightcorner p{
		position: relative;
		margin-top: 25px !important;
		font-size: 15px !important;
	}

	.userhome button.clear{
		width: 160px;
	}
	 
}

/* desktop styles */ 
@media screen and (min-width: 1200px){
    /* .headbar{
		display: grid;
		grid-template-columns: repeat(12,1fr);
		width: 100%;
		position: relative;
		top: 0;
		background: #FF416C;
		background: linear-gradient(to right, #FF4B2B, #FF416C);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 0 0;
		text-align: left;
		height: 75px;
	}
	.headbar div{
		text-align: center;
		max-width: 100%;
	}
	.headbar .headlogo{
		color: #eee;
		font-size: 34px;
	}
	.headbar div:nth-child(1){
		grid-column: 1/3;
		grid-row: 1;
	}
	.headbar div:nth-child(2){
		grid-column: 4/7;
		grid-row: 2;
	}
	.headbar div:nth-child(3){
		grid-column: 8/13;
		grid-row: 2;
	} */
}